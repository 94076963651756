export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button-secondary',
  FACET_PANEL_CLASS: 'page-sidebar',
  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',
  LOAD_MORE_BUTTON_CLASS: '',
  MAIN_CONTENT_CLASS: 'page-content',
  MAX_PRICE: 'Max.',
  MIN_PRICE: 'Min.',
  PRODUCT_REPEATER_CLASS: 'productGrid',
  SELECT_CLASS: 'form-select',
  SORT_BY: 'Sort By',
  SORT_CONTAINER_CLASS: 'form-field',
  FACET_TOGGLE_LABEL_CLASS: 'form-label',
  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This Part Fits:',
  SEARCH_BOX_PLACEHOLDER: 'Search for a product',
  SEARCH_BOX_CLASS: 'navUser-action',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'btn-search',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
};
